import { THEME } from './App';

const light = {
    '--background': 'white',
    '--navbar': '#222',
    '--headers': '#222',
    '--text': '#222',
    '--link': 'darkblue',
    '--button-background': '#222',
    '--button-text': '#9d9d9d',
    '--button-highlight': 'black',
    '--tr-hover': '#FFA300',
}

const dark = {
    '--background': 'black',
    '--navbar': '#222',
    '--headers': 'white',
    '--text': 'white',
    '--link': 'lightblue',
    '--button-background': '#222',
    '--button-text': 'white',
    '--button-highlight': '#444',
    '--tr-hover': 'grey',
    '--calendar-filter': 'invert(.9) saturate(0.5) hue-rotate(145deg)',
} 

const red = {
    '--background': 'lightcoral',
    '--navbar': 'darkred',
    '--headers': 'darkred',
    '--text': 'darkred',
    '--link': 'red',
    '--button-background': 'darkred',
    '--button-text': 'lightcoral',
    '--button-highlight': 'brown',
    '--tr-hover': 'brown',
    '--calendar-filter': 'hue-rotate(140deg)',
} 

const blue = {
    '--background': 'powderblue',
    '--navbar': 'darkblue',
    '--headers': 'darkblue',
    '--text': 'darkblue',
    '--link': 'blue',
    '--button-background': 'darkblue',
    '--button-text': 'powderblue',
    '--button-highlight': 'cornflowerblue',
    '--tr-hover': 'cornflowerblue',
} 

const green = {
    '--background': 'lightgreen',
    '--navbar': 'darkgreen',
    '--headers': 'green',
    '--text': 'darkgreen',
    '--link': 'green',
    '--button-background': 'darkgreen',
    '--button-text': 'lightgreen',
    '--button-highlight': 'green',
    '--tr-hover': '#28a428',
    '--calendar-filter': 'hue-rotate(75deg);',
} 

const purple = {
    '--background': 'lavender',
    '--navbar': 'indigo',
    '--headers': 'indigo',
    '--text': 'indigo',
    '--link': 'rebeccapurple',
    '--button-background': 'indigo',
    '--button-text': 'lavender',
    '--button-highlight': 'orchid',
    '--tr-hover': 'orchid',
    '--calendar-filter': 'hue-rotate(44deg)',
} 

const prismarine = {
    '--background': '#0e4b40',
    '--navbar': '#03312e',
    '--headers': '#64ED8F',
    '--text': 'white',
    '--link': '#8fd9d4',
    '--button-background': '#03312e',
    '--button-text': '#8fd9d4',
    '--button-highlight': 'lightcoral',
    '--tr-hover': 'lightcoral',
}

const brown = {
    '--background': '#5e3023',
    '--navbar': '#c08552',
    '--headers': 'sandybrown',
    '--text': 'burlywood',
    '--link': 'silver',
    '--button-background': 'silver',
    '--button-text': '#5e3023',
    '--button-highlight': 'peachpuff',
    '--tr-hover': 'peru',
}

const navy = {
    '--background': '#002b45',
    '--navbar': '#1d5279',
    '--headers': 'paleturquoise',
    '--text': 'white',
    '--link': 'steelblue',
    '--button-background': '#1d5279',
    '--button-text': 'paleturquoise',
    '--button-highlight': 'steelblue',
    '--tr-hover': 'navy',
}

const rainbow = {
    '--background': 'linear-gradient(90deg, rgb(255, 230, 230) 3%, rgb(255, 243, 228) 13%, rgb(254, 255, 231) 25%, rgb(238, 255, 235) 38%, rgb(231, 251, 253) 50%, rgb(231, 240, 255) 63%, rgb(240, 236, 255) 75%, rgb(255, 237, 255) 88%, rgb(255, 228, 246) 100%)',
    '--background-blow-up': '200% 200%',
    '--navbar': 'linear-gradient(90deg, rgba(255,164,164,1) 0%, rgba(255,214,165,1) 5%, rgba(253,255,182,1) 10%, rgba(202,255,191,1) 15%, rgba(155,246,255,1) 20%, rgba(160,196,255,1) 25%, rgba(189,178,255,1) 30%, rgba(240,190,255,1) 35%, rgba(255,198,255,1) 40%, rgba(255,185,202,1) 45%, rgba(255,164,164,1) 50%, rgba(255,214,165,1) 55%, rgba(253,255,182,1) 60%, rgba(202,255,191,1) 65%, rgba(155,246,255,1) 70%, rgba(160,196,255,1) 75%, rgba(189,178,255,1) 80%, rgba(240,190,255,1) 85%, rgba(255,198,255,1) 90%, rgba(255,185,202,1) 95%, rgba(255,164,164,1) 100%)',
    '--headers': 'black',
    '--text': 'black',
    '--link': 'purple',
    '--button-background': 'linear-gradient(90deg, rgba(255,164,164,1) 0%, rgba(255,214,165,1) 5%, rgba(253,255,182,1) 10%, rgba(202,255,191,1) 15%, rgba(155,246,255,1) 20%, rgba(160,196,255,1) 25%, rgba(189,178,255,1) 30%, rgba(240,190,255,1) 35%, rgba(255,198,255,1) 40%, rgba(255,185,202,1) 45%, rgba(255,164,164,1) 50%, rgba(255,214,165,1) 55%, rgba(253,255,182,1) 60%, rgba(202,255,191,1) 65%, rgba(155,246,255,1) 70%, rgba(160,196,255,1) 75%, rgba(189,178,255,1) 80%, rgba(240,190,255,1) 85%, rgba(255,198,255,1) 90%, rgba(255,185,202,1) 95%, rgba(255,164,164,1) 100%)',
    '--button-text': 'Black',
    '--button-highlight': 'beige',
    '--tr-hover': 'orchid',
    '--background-animation': 'gradient 10s infinite linear',
}

const ace = {
    '--background': 'black',
    '--navbar': 'linear-gradient(90deg, #181818 10%, #A3A3A3 30% 40%, #FFFFFF 50% 60%, #800080 80%)',
    '--headers': 'white',
    '--text': 'white',
    '--link': '#800080',
    '--button-background': '#800080',
    '--button-text': 'black',
    '--button-highlight': '#A3A3A3',
    '--tr-hover': '#A3A3A3',
    '--calendar-filter': 'hue-rotate(44deg) invert(.75) saturate(1) hue-rotate(170deg)',
} 

const bi = {
    '--background': '#e6bce3',
    '--navbar': 'linear-gradient(90deg, #D60270 30%, #9B4F96 45% 55%, #0038A8 70%)',
    '--headers': 'white',
    '--text': 'white',
    '--link': '#0038A8',
    '--button-background': '#D60270',
    '--button-text': 'white',
    '--button-highlight': 'lightskyblue',
    '--tr-hover': '#0038A8',
    '--calendar-filter': 'invert(100%) saturate(100%) invert(100%) sepia(21%) saturate(4977%) hue-rotate(215deg) brightness(95%) contrast(90%)',
}

const enby = {
    '--background': 'black',
    '--navbar': 'linear-gradient(90deg, #FFF430 10%, #FFFFFF 30% 40%, #9C59D1 60% 80%, #181818 90%)',
    '--headers': '#FFF430',
    '--text': 'white',
    '--link': '#9C59D1',
    '--button-background': '#9C59D1',
    '--button-text': 'black',
    '--button-highlight': '#FFF430',
    '--tr-hover': '#FFF430',
}

const trans = {
    '--background': 'white',
    '--navbar': 'linear-gradient(90deg, #5BCEFA 10%, #F5A9B8 22.5% 32.5%,  #FFFFFF 45% 55%, #F5A9B8 67.5% 77.5%, #5BCEFA 90%)',
    '--headers': '#F5A9B8',
    '--text': 'grey',
    '--link': '#5BCEFA',
    '--button-background': '#F5A9B8',
    '--button-text': 'black',
    '--button-highlight': '#5BCEFA',
    '--tr-hover': '#5BCEFA',
}

const pan = {
    '--background': 'white',
    '--navbar': 'linear-gradient(90deg, #FF218C 10%,  #FFD800 45% 55%, #21B1FF 90%)',
    '--headers': '#FFD800',
    '--text': '#FF218C',
    '--link': '#21B1FF',
    '--button-background': '#21B1FF',
    '--button-text': 'black',
    '--button-highlight': '#FF218C',
    '--tr-hover': '#21B1FF',
    '--calendar-filter': 'hue-rotate(197deg)',
}

const patrick = {
    '--background': '#363a4f',
    '--navbar': '#181926',
    '--headers': '#8087a2',
    '--text': '#8087a2',
    '--link': '#ed8796',
    '--button-background': '#181926',
    '--button-text': '#9d9d9d',
    '--button-highlight': 'black',
    '--tr-hover': '#181926',
    '--calendar-filter': 'brightness(0.65) saturate(15) hue-rotate(125deg)',
    '--global-font': 'italic 1.2rem Fira Sans,serif',
}

const jensen = {
    '--background': 'black',
    '--navbar': '#008F28',
    '--headers': '#008F28',
    '--text': 'grey',
    '--link': '#5339FF',
    '--button-background': '#008F28',
    '--button-text': 'lightgreen',
    '--button-highlight': 'black',
    '--tr-hover': '#856514',
    '--calendar-filter': 'invert(1) saturate(0.5) hue-rotate(60deg)',
    '--global-font': 'bold 1.2rem courier new',
}

const spooky = {
    '--background': 'black',
    '--navbar': 'orange',
    '--headers': 'orange',
    '--text': 'white',
    '--link': 'magenta',
    '--button-background': 'magenta',
    '--button-text': 'black',
    '--button-highlight': 'white',
    '--tr-hover': 'orange',
    '--calendar-filter': 'invert(1) saturate(0.5) hue-rotate(60deg)',
}

const holidays = {
    '--background': 'white',
    '--navbar': '#325632',
    '--headers': '#d20018',
    '--text': '#48391c',
    '--link': '#325632',
    '--button-background': '#325632',
    '--button-text': 'silver',
    '--button-highlight': '#ef4b2f',
    '--tr-hover': '#ef4b2f',
    '--calendar-filter': 'hue-rotate(140deg)',
}

export const THEMELIST = {default: 'Default',
                          light: 'Light',
                          dark: 'Dark', 
                          red: 'Red', 
                          blue: 'Blue', 
                          green: 'Green', 
                          purple: 'Purple', 
                          prismarine: 'Prismarine',
                          brown: 'Brown',
                          navy: 'Navy',
                          patrick: 'Patrick',
                          jensen: 'Jensen',
                          rainbow: 'Rainbow', 
                          trans: 'Trans', 
                          enby: 'Enby', 
                          bi: 'Bi', 
                          ace: 'Ace', 
                          pan: 'Pan',
                          spooky: 'Spooky',
                          holidays: 'Holidays'}

export const THEMEOBJECTS = {Light: light,
                Dark: dark, 
                Red: red, 
                Blue: blue, 
                Green: green, 
                Purple: purple, 
                Prismarine: prismarine,
                Brown: brown,
                Navy: navy,
                Rainbow: rainbow,
                Trans: trans,
                Enby: enby,
                Bi: bi,
                Ace: ace,
                Pan: pan,
                Patrick: patrick,
                Jensen: jensen,
                Spooky: spooky,
                Holidays: holidays,
            }

export default function themeLoader() {
    const root = document.querySelector(':root')
    try {
        let theme = localStorage.getItem(THEME);
        if(theme === THEMELIST.default) {
            theme = THEMELIST.light
        }
        const themeObject = THEMEOBJECTS[theme]
        Object.keys(themeObject).forEach((key) => {
            const value = themeObject[key];
            root.style.setProperty(key, value);
        });
    } catch (e) { // If Error, print out and load Light Mode
        console.error(e)
        Object.keys(THEMEOBJECTS.Light).forEach((key) => {
            const value = light[key];
            root.style.setProperty(key, value);
        });
    }
}