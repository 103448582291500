import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { blankCheck, dceCheck } from '../../Errors';
import { USERID } from '../../App';
import { useNavigate } from 'react-router-dom';

export default function CSVEvent(props) {
  const [orderedInput, setOrderedInput] = useState([])
  const [parsing, setParsing] = useState(false)
  const [finished, setFinished] = useState(false)
  const [submit, setSubmit] = useState(false)
  const [error, setError] = useState(false)
  const [eventID, setEventID] = useState(0)
  const [events, setEvents] = useState([])
  const [noEvent, setNoEvent] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const url = `${props.constants.hostname}/api/secretary/events`
    fetch(url, {
      headers: {Authorization: `${localStorage.getItem(USERID)}`}
    })
    .then((res) => {
        if (!res.ok) {
            return false;
        }
        return res.json();
    })
    .then((data) => {
        if (!data) { return; }
        setEvents(data)
    })
    .catch((e) => { console.error(e); });
  }, [])

  function errorCheck(newUser) {
    if (!blankCheck(newUser.DCE)) {
      if (dceCheck(newUser.DCE)) {
        newUser.DCEError = 'Invalid Entry'
        return false;
      }
    } else {
      newUser.DCEError = 'Blank Entry'
      return false;
    }
    if (eventID === 0) {
      if (!noEvent) {
        alert('No event selected. Please select an event')
        setNoEvent(true)
      }
      return false;
    }
    return true;
  }

  function sendData(newUser) {
    const url = `${props.constants.hostname}/api/secretary/addAttendant`
    return new Promise((resolve, reject) => {
      fetch(url, {
        body: JSON.stringify({ userInfo: newUser.DCE, eventID: eventID }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${localStorage.getItem(USERID)}`
        },
        method: 'POST',
      })
      .then((res) => {
        if (!res.ok) {
          reject(new Error('Entry already exists'))
        } else {
          resolve();
        }
      })
    })
  }

  function resolveData(errorInput) {
    if (errorInput.length !== 0) {
      setOrderedInput(errorInput)
      setError(true)
      setNoEvent(false)
    } else {
      setSubmit(true)
      setNoEvent(false)
    }
  }

  async function submitUpload() {
    const errorInput = [];
    let counter = 0;
    const elementsToAdd = orderedInput.length
    for (let i = 0; i < orderedInput.length; i++) {
      const newUser = orderedInput[i];
      if (!errorCheck(newUser)) {
        errorInput[counter] = newUser;
        counter++;
      } else {
        await sendData(newUser, i)
        .then(() => {
          if ((i + 1) === elementsToAdd) {
            resolveData(errorInput);
          }
        })
        // eslint-disable-next-line
        .catch(() => {
          errorInput[counter] = newUser;
          counter++;
          if (i + 1 === elementsToAdd) {
            resolveData(errorInput);
          }
        })
      }
    }
  }

  function elementChange(arrayIndex, event) {
    const fixedInput = [...orderedInput];
    fixedInput[arrayIndex].DCE = event.target.value
    setOrderedInput(fixedInput)
  }

  function confirm(user) {
    if (window.confirm('Delete this user from the event attendance list?')) {
      const tempArray = [...orderedInput];
      for (let i = 0; i < orderedInput.length; i++) {
        const tempUser = orderedInput[i];
        if (tempUser.DCE === user.DCE) {
          tempArray.splice(i, 1)
          setOrderedInput(tempArray)
          break;
        }
      }
    }
  }

  function readFile(event) {
    setParsing(true)
    const file = event.target.files[0];
    const reader = new FileReader();
    let counter = 0;
    reader.onload = () => {
      const text = reader.result;
      const rows = text.split('\n');
      let first = true;
      let order = 0;
      const reorderedElements = [];
      rows.forEach((element) => {
        const rowItems = element.split(',');
        if (first) {
          //  Finding the column that contains the dce's for the users
          //  (Allows for CSV with columns of unneeded elements)
          for (let i = 0; i < rowItems.length; i++) {
            rowItems[i] = rowItems[i].replace(/["']+/g, '').replace(/\s/g, '').trim()
            if (rowItems[i].toLowerCase() === 'dce') {
              order = i;
              break;
            }
          }
          first = false;
        }
        else if (rowItems[0] !== '') {
          rowItems[order] = rowItems[order].replace(/["']+/g, '').replace(/\s/g, '').trim()
          const newUser = {
            DCE: rowItems[order],
            DCEError: '',
          }
          errorCheck(newUser)
          reorderedElements[counter] = newUser;
          counter++;
        }
      });
      setOrderedInput(reorderedElements)
      setParsing(false)
      setFinished(true)
      setNoEvent(false)
    }
    reader.readAsText(file);
  }

  if(submit) {
    navigate('/secretary/events')
  }

  return (
    <div>
      {error ?
        <h2>The following elements could not be added. Please check to ensure they aren't already apart of the event</h2>
        :
        null
      }
      <h3>File Upload</h3>
      <hr />
      <form>
        <label className='secretaryLabel'>
          Event:
          <select
            value={eventID}
            onChange={(e) => setEventID(e.target.value)}
            className='secretaryInput'
          >
            <option default value={0}>---</option>
            {
              events.map((event) =>
                <option key={event.Eid} value={event.Eid}>{event.Name}</option>
              )
            }
          </select>
        </label>
      </form>
      <div>
        <div className='oneline'>
          <p className='fileInputTag'>Select a CSV file to upload:
            <input type='file' className='fileInput' onChange={readFile} />
          </p>
          {parsing ?
            <div className='loader'></div>
            :
            null
          }
        </div>
        {finished ?
          <div>
            <div className='oneline'>
              <p className='fileInfo'>Elements have finished loading!</p>
              <p className='fileInfo' style={{marginLeft: '3px'}}>Please review the following entries and make any changes.</p>
            </div>
            <p className='fileInfo'>Number of users read: {orderedInput.length}</p>
            <div className='oneline'>
              <p className='fileInfo'>When done, please hit the submit button.</p>
              <button className='fileSubmit' onClick={submitUpload}>Submit</button>
            </div>
          </div>
          :
          null
        }
        </div>
        <div className='tableDiv'>
          <table className='userTable'>
            <thead>
              <tr className='userRow userHeaderRow'>
                <th className='userHeader'>DCE</th>
                <th className='userHeader'>Delete</th>
              </tr>
            </thead>
            <tbody>
              {orderedInput.map((user, index) =>
                <tr key={index} className='userRow'>
                  <td className='userEntry'>
                    {orderedInput[index].DCEError === '' ?
                    <input
                      type='text'
                      onChange={(e) => elementChange(index, e)}
                      value={orderedInput[index].DCE}
                    />
                    :
                    <div className='tooltip'>
                      <input
                        type='text'
                        style={{background: 'yellow'}}
                        onChange={(e) => elementChange(index, e)}
                        value={orderedInput[index].DCE}
                      />
                      <span className='tooltiptext'>
                        {orderedInput[index].DCEError}
                      </span>
                    </div>
                    }
                  </td>
                  <td className='userEntry'>
                    <FontAwesomeIcon
                      icon='trash-alt'
                      className='faDelete'
                      onClick={() => confirm(index, user)}
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
    </div>
  );
}