import React, { useEffect, useState } from 'react';
import { authRole } from '../../..';
import { USERID } from '../../App';
import { Link, useNavigate } from 'react-router-dom';
import '../../../css/voting.css';

export default function Home(props) {
  const [goodStanding, setGoodStanding] = useState(null)
  const [availableElections, setAvailable] = useState([])
  const navigate = useNavigate()
  
  useEffect(() => {
    let url = `${props.constants.hostname}/api/goodStanding`
    fetch(url, {
      headers: {Authorization: `${localStorage.getItem(USERID)}`}
    })
    .then((res) => {
      if (!res.ok) {
        return false;
      }
      return res.json();
    })
    .then((data) => {
      if (data) { 
        setGoodStanding(true) 
        url = `${props.constants.hostname}/api/voting/availableElections`
        fetch(url, {
          headers: {Authorization: `${localStorage.getItem(USERID)}`}
        })
        .then((res) => {
          if (!res.ok) {
            return false;
          }
          return res.json();
        })
        .then((data2) => {
          if (!data2) { return; }
          setAvailable(data2)
        })
        .catch((error) => { console.error(error); });
      }
      else {
        setGoodStanding(false)
      }
    })
    .catch((error) => { console.error(error); });
  }, [])

  function convertElectionTR(election) {
    return (
      <tr key={election.ElectionName}>
        <td>
        <Link className='songLink' style={{textDecoration: 'underline'}} to={`/vote/election/${election.election_id}`}>{election.ElectionName}</Link></td>
        <td>{election.EndDate}</td>
      </tr>
    )
  }

  if(!goodStanding) {
    navigate('unauthorized', {replace: true})
  }
  
  const roles = props.constants.roles;
  return (
      <div>
          {authRole(props.userRole, roles.president) ?
          <div>
            <Link
              to='/vote/create'
              className='secBarLink'
            >
              <div className='oneline'>
                <p className='secBarText'>
                  Create Election
                </p>
              </div>
              <br/>
            </Link>
            <Link
              to='/vote/results'
              className='secBarLink'
            >
              <div className='oneline'>
                <p className='secBarText'>
                  View Election Results
                </p>
              </div>
              <br/>
            </Link>
          </div>
          :
          null
        }
        <br/>
        <h2>Elections: </h2>
        <table>
          <tr>
            <th style={{width: '5%'}}>Title</th>
            <th style={{width: '5%'}}>End Date</th>
          </tr>
          {availableElections.map((election) => convertElectionTR(election))}
        </table>
    </div>
  )
}