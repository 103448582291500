import React, { useEffect, useState } from 'react';
import { USERID } from '../App';

export default function Attendance(props) {
  const [eventAttendance, setEventAttendance] = useState({rehearsals: 0, events: 0, mHockey: 0, wHockey: 0, mSoccer: 0, wSoccer: 0, mLax: 0, wLax: 0, other: 0})
  const [exempt, setExempt] = useState({exempt: false, exemptDesc: '', })
  const [userInfo, setUserInfo] = useState({FirstName: '', LastName: '', WGameGo: false, MGameGo: false, GStanding: false, FGStanding: false,})

  function handleAssign(data) {
    const newAttendance = {...eventAttendance}
    data.forEach((element) => {
      if (element.Rehearsal === 'Y') {
        newAttendance.rehearsals = eventAttendance.rehearsals + element.count
      }
      switch(element.Type) {
        case 'Rehearsal':
          break;
        case 'MHockey':
          newAttendance.mHockey = eventAttendance.mHockey + element.count
          newAttendance.events += element.count
          break;
        case 'WHockey':
          newAttendance.wHockey = eventAttendance.wHockey + element.count
          newAttendance.events += element.count
          break;
        case 'MSoccer':
          newAttendance.mSoccer = eventAttendance.mSoccer + element.count
          newAttendance.events += element.count
          break;
        case 'WSoccer':
          newAttendance.wSoccer = eventAttendance.wSoccer + element.count
          newAttendance.events += element.count
          break;
        case 'MLax':
          newAttendance.mLax = eventAttendance.mLax + element.count
          newAttendance.events += element.count
          break;
        case 'WLax':
          newAttendance.wLax = eventAttendance.wLax + element.count
          newAttendance.events += element.count
          break;
        case 'Other':
          newAttendance.other = eventAttendance.other + element.count
          newAttendance.events += element.count
          break;
        default:
          console.error('Unhandled event type. Please let webmaster know');
      }
    });
    setEventAttendance(newAttendance)
  }

  useEffect(() => {
    let url = `${props.constants.hostname}/api/attendance`;
    fetch(url, {
      headers: {Authorization: `${localStorage.getItem(USERID)}`}
    })
    .then((res) => {
      if (!res.ok) {
        return false;
      }
      else {
        return res.json();
      }
    })
    .then((data) => {
      handleAssign(data)
    })
    .catch((error) => { console.error(error); })

    url = `${props.constants.hostname}/api/exempt`;
    fetch(url, {
      headers: {Authorization: `${localStorage.getItem(USERID)}`}
    })
    .then((res) => {
      if (!res.ok) {
        return false;
      }
      else {
        return res.json();
      }
    })
    .then((data) => {
      if (data[0].Exempt === 1) {
        const newExempt = {...userInfo}
        newExempt.exempt = data[0].Exempt
        newExempt.exemptDesc = data[0].ExemptDesc
        setExempt(newExempt)
      }
    })
    .catch((error) => { console.error(error); })

    url = `${props.constants.hostname}/api/statuses`;
    fetch(url, {
      headers: {Authorization: `${localStorage.getItem(USERID)}`}
    })
    .then((res) => {
      if (!res.ok) {
        return false;
      }
      else {
        return res.json();
      }
    })
    .then((data) => {
      const newInfo = {...userInfo}
      newInfo.FirstName = data.FirstName
      newInfo.LastName = data.LastName
      newInfo.WGameGo = data.WGameGo
      newInfo.MGameGo = data.MGameGo
      newInfo.GStanding = data.GStanding
      newInfo.FGStanding = data.FGStanding
      setUserInfo(newInfo)
    })
    .catch((error) => { console.error(error); })
  }, [])

  function warningController() {
    if (eventAttendance.rehearsals < 3) {
      if (exempt.exempt) {
        return (
          <div>
            <div className='oneline attendanceh3'>
              <h3 className='attendanceh3'>
                You currently have exemption from the following event types:
              </h3>
              <h3 className='attendanceh3' style={{marginLeft: '5px'}}>
                {exempt.exemptDesc}
              </h3>
            </div>
            <h3 className='attendanceh3'>
              This may or may not allow you to attend games.
            </h3>
          </div>
        );
      }
      else if (eventAttendance.rehearsals === 2) {
        return (
          <h3 className='attendanceh3' id='warning'>
            Warning: You currently cannot attend men's hockey games
          </h3>
        );
      }
      return (
        <h3 className='attendanceh3' id='warning'>
          Warning: You currently cannot attend men's or women's hockey games
        </h3>
      )
    }
    return null;
  }

  return (
    <div id='attendance'>
      <h1>
        Attendance for:<br></br>{userInfo.LastName}, {userInfo.FirstName}
      </h1>
      {warningController()}
      <div>
        <h3 className='attendanceh3'>
          Rehearsals: {eventAttendance.rehearsals}
        </h3>
        <h3 className='attendanceh3'>
          Events: {eventAttendance.events}
        </h3>
        <h3 className='attendanceh3'>
          Good Standing (Current Semester): {userInfo.GStanding === 1 ? 'True' : 'False'}
        </h3>
        <h3 className='attendanceh3'>
          Projected Good Standing (For Future Semester): {userInfo.FGStanding === 1 ? 'True' : 'False'}
        </h3>
        <p>Men's Hockey Games: {eventAttendance.mHockey}</p>
        <p>Women's Hockey Games: {eventAttendance.wHockey}</p>
        <p>Men's Soccer Games: {eventAttendance.mSoccer}</p>
        <p>Women's Soccer Games: {eventAttendance.wSoccer}</p>
        <p>Men's Lacrosse Games: {eventAttendance.mLax}</p>
        <p>Women's Lacrosse Games: {eventAttendance.wLax}</p>
        <p>Other events: {eventAttendance.other}</p>
      </div>
      <br/>
      <div>
        <div id='disclaimer'>
          <b>Disclaimer:</b> <br></br>
          <i> - These may or may not be up to date with the latest events <br></br>
          - If something doesn't look right please contact the secretary <br></br>
          - Good Standing calculations can be found in the constitution <br></br>
        </i></div>
      </div>
    </div>
  );
}
