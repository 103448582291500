import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { USERID } from '../../App';

const SECTIONS = {
    Flutes: {name: 'Flutes', checked: false}, 
    Clarinets: {name: 'Clarinets', checked: false},
    Saxophones: {name: 'Saxophones', checked: false},
    Trumpets: {name: 'Trumpets', checked: false},
    Mellophones: {name: 'Mellophones', checked: false},
    TenorSaxophones: {name: 'Tenor Saxophones', checked: false},
    Trombones: {name: 'Trombones', checked: false},
    Lows: {name: 'Lows', checked: false},
    Percussion: {name: 'Percussion', checked: false}
}

const STATUSES = {
    C: {name: 'Current Student', checked: false},
    A: {name: 'Active Alumni', checked: false},
    I: {name: 'Inactive Alumni', checked: false}
}

export default function EmailLists(props) {
    const [users, setUsers] = useState([])
    const [extraSections, setExtraSections] = useState([])
    const [userPage, setUserPage] = useState('')
    const [sections, setSections] = useState(JSON.parse(JSON.stringify(SECTIONS)))
    const [statuses, setStatuses] = useState(JSON.parse(JSON.stringify(STATUSES)))
    const [emailList, setEmailList] = useState([])
    const navigate = useNavigate()

    if(userPage) {
        navigate(`/secretary/user/${userPage}`)
      }

    useEffect(() => {
        let url = `${props.constants.hostname}/api/secretary/users`;
        fetch(url, {
        headers: {Authorization: `${localStorage.getItem(USERID)}`}
        })
        .then((res) => {
            if (!res.ok) {
                return false;
            }
            return res.json();
        })
        .then((data) => {
            if (!data) { return; }
            setUsers(data)
        })
        .catch((error) => { console.error(error); });

        url = `${props.constants.hostname}/api/secretary/extraSections`;
        fetch(url, {
        headers: {Authorization: `${localStorage.getItem(USERID)}`}
        })
        .then((res) => {
            if (!res.ok) {
                return false;
            }
            return res.json();
        })
        .then((data) => {
            if (!data) { return; }
            setExtraSections(data)
        })
        .catch((error) => { console.error(error); });
    }, [])

    function createEmailLists() {
        const newEmailList = []
        for(const user of users) {
            const sectionChecked = sections[user.SECTION].checked
            const statusChecked = statuses[user.Status].checked
            const userExtraSections = extraSections.filter((es) => es.MemberDCE === user.DCE)
            for(const section of Object.keys(sections)) {
                const userSection = userExtraSections.find((us) => us.ExtraSection === section)
                if(userSection && statusChecked) {
                    newEmailList.push(user)
                }
            }
            if(sectionChecked && statusChecked) {
                newEmailList.push(user)
            }
        }
        newEmailList.sort((a, b) => { return a.DCE.localeCompare(b.DCE) })
        setEmailList(newEmailList)
    }

    useEffect(createEmailLists, [sections, statuses])

    function handlePresetActiveBand() {
        const newStatuses = JSON.parse(JSON.stringify(STATUSES))
        const newSections = JSON.parse(JSON.stringify(SECTIONS))
        newStatuses.C.checked = true
        newStatuses.A.checked = true
        newStatuses.I.checked = false
        for(const sec of Object.keys(newSections)) {
            newSections[sec].checked = true
        }
        setStatuses(newStatuses)
        setSections(newSections)
    }

    function handlePresetWholeBand() {
        const newStatuses = JSON.parse(JSON.stringify(STATUSES))
        const newSections = JSON.parse(JSON.stringify(SECTIONS))
        for(const s of Object.keys(newStatuses)) {
            newStatuses[s].checked = true
        }
        for(const s of Object.keys(newSections)) {
            newSections[s].checked = true
        }
        setStatuses(newStatuses)
        setSections(newSections)
    }

    function handlePresetMultiSection() {
        const newEmailList = []
        const foundUsers = new Set()
        for(const es of extraSections) {
            if(!foundUsers.has(es.DCE) && es.Status === 'C') {
                newEmailList.push(es)
                foundUsers.add(es.DCE)
            }
        }
        setEmailList(newEmailList)
    }

    function handleSectionChange(section) {
        const tempSections = {...sections};
        tempSections[section].checked = !tempSections[section].checked;
        setSections(tempSections)
    }

    function sectionRender() {
        return (
            <div>
                {Object.keys(sections).map((s) => {
                    return (
                        <div key={s}>
                            <label 
                                className='secretaryLabel sectionCheck align-center'
                                key={sections[s].name}
                            >
                            <input 
                                type='checkbox'
                                className='sectionCheck'
                                onChange={() => handleSectionChange(s)}
                                checked={sections[s].checked}
                            />{sections[s].name}
                            </label>
                            <br/>
                        </div>
                    )
                })}
            </div>
        )
    }

    function handleStatusChange(status) {
        const tempStatuses = {...statuses};
        tempStatuses[status].checked = !tempStatuses[status].checked;
        setStatuses(tempStatuses)
    }

    function statusRender() {
        return (
            <div>
                {Object.keys(statuses).map((s) => {
                    return (
                        <div key={s}>
                            <label 
                                className='secretaryLabel sectionCheck align-center'
                            >
                            <input 
                                type='checkbox'
                                className='sectionCheck'
                                onChange={() => handleStatusChange(s)}
                                checked={statuses[s].checked}
                            /> {statuses[s].name}
                            </label>
                            <br/>
                        </div>
                    )
                })}
            </div>
        )
    }

    function generateRow(user) {
        return (
            <tr key={user.DCE} className='userRow' onClick={() => setUserPage(user.DCE)}>
            <td className='userEntry'>{user.LastName}</td>
            <td className='userEntry'>{user.FirstName}</td>
            <td className='userEntry'>{user.Email}</td>
            </tr>
        )
    }

    function copyEmailList() {
        let emailString = ''
        for(const user of emailList) {
            emailString = `${emailString}${user.Email},`
        }
        navigator.clipboard.writeText(emailString)
        alert('Copied Email List to Clipboard')
    }

    return (
        <div>
            <h3>Presets:</h3>
            <button onClick={handlePresetActiveBand}>Active Band</button>
            <button onClick={handlePresetWholeBand}>Whole Band</button>
            <button onClick={handlePresetMultiSection}>Current Students in Multiple Sections</button>
            <h3>Options:</h3>
            <p>Sections</p>
            {sectionRender()}
            <br/>
            <p>Statuses</p>
            {statusRender()}
            <br/>
            <br/>
            <button onClick={copyEmailList}>Copy Email List to Clipboard</button>
            <br/>
            <p>Length: {emailList.length}</p>
            <div className='tableDiv'>
            <table className='userTable'>
            <thead>
                <tr className='userRow userHeaderRow'>
                <th className='userHeader'>Last Name</th>
                <th className='userHeader'>First Name</th>
                <th className='userHeader'>Email</th>
                </tr>
            </thead>
            <tbody>
                {emailList.map((user) =>
                    generateRow(user)
                )}
            </tbody>
            </table>
        </div>
        </div>
    );
}
