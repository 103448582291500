import React from 'react';

export default function Footer(props) {
  return (
    <footer className={`${props.cssOff ? '' : 'footer'}`}>
      <div className={`${props.cssOff ? '' : 'center footText'}`}>Updated for the 2024-2025 Academic Year</div>
      <div className={`${props.cssOff ? '' : 'center footText'}`}>For Use by the RIT Pep Band Only</div>
    </footer>
  );
}
