import React, { useState } from 'react';
import { USERID } from '../../App';
import { blankCheck, eventCheck } from '../../Errors';
import { Link, useNavigate } from 'react-router-dom';

export default function CreateElection(props) {
    const [title, setTitle] = useState('')
    const [options, setOptions] = useState([{id: 0, name: ''}])
    const [endDate, setEndDate] = useState('')
    const [abstainable, setAbstainable] = useState('Y')
    const [error, setError] = useState(false)
    const [submit, setSubmit] = useState(false)
    const navigate = useNavigate()

    function handleTitleChange(event) {
        setTitle(event.target.value)
    }

    function handleAddOption() {
        setOptions(options.concat({id: options.length, name: ''}))
    }

    function handleRemoveOption(event) {
        if(options.length === 1) {
            window.alert('An Election Must have at Least 1 candidate')
        }
        else {
            const newOptions = [...options]
            newOptions.splice(event.target.id, 1)
            setOptions(newOptions)
        }
    }

    function handleOptionChange(event) {
        const newOptions = options
        newOptions[event.target.id].name = event.target.value
        setOptions(newOptions)
    }

    function handleDateChange(event) {
        setEndDate(event.target.value)
    }

    function handleAbstainChange(event) {
        setAbstainable(event.target.value)
    }

    function errorCheck() {
        if (blankCheck(title)) {
            console.error('Blank field: Name')
            alert('Blank field: First Name')
            return false;
        }
        if(eventCheck(title)) {
            console.error(`Invalid Name: ${title}`)
            alert(`Invalid Name: ${title}\nPlease Fix Input`);
            return false;
        }
        if (blankCheck(endDate)) {
            console.error('Blank field: End Date')
            alert('Blank field: Date')
            return false;
        }
        return true;
      }

    function handleSubmit() {
        if(errorCheck()) {
            setError(false)
            const candidates = options.map((e) => e.name)
            const election = {
                title: title,
                endDate: endDate,
                candidates: candidates,
                abstainable: abstainable
            }
            const url = `${props.constants.hostname}/api/voting/createElection`;
            fetch(url, {
                body: JSON.stringify({ election: election }),
                headers: {
                Authorization: `${localStorage.getItem(USERID)}`,
                'Content-Type': 'application/json',
                },
                method: 'POST',
            })
            .then((res) => {
                if (!res.ok) {
                    setError(true)
                    return false;
                }
                else {
                    setSubmit(true)
                    return true
                }
            })
            .catch((e) => { console.error(e); })
        }
    }
    if(error) {
        window.alert('An Error Has Occured, please try again, and if the problem continues, DM the webmaster')
    }

    if(submit) {
        navigate('/vote')
    }

    return (
        <div>
            <Link
                to='/vote'
                className='secBarLink'
            >
                <p className='secBarText'>Back to "Election Home"</p>
            </Link>
            <h2>Create Election</h2>
            <label htmlFor='title'>Election Title [spaces are fine now :)]:</label>
            <input id='title' type='text' onChange={handleTitleChange}></input>
            <br/>
            <label className='secretaryLabel'>
                Can Users Abstain?
                <select
                    value={abstainable}
                    onChange={handleAbstainChange}
                    className='secretaryInput'
                >
                <option default value='Y'>Yes</option>
                <option value='N'>No</option>
                </select>
            </label>
            <br/>
            <button onClick={handleAddOption} style={{width: '125px'}}>Add Candidate</button>
            {options.map((option) =>
                <div key={options.indexOf(option)} style={{marginBottom: '5px'}}>
                    <input type='text' default={option.name} id={option.id} style={{marginRight: '5px'}} onChange={(e) => handleOptionChange(e)}></input>
                    <button id={options.indexOf(option)} onClick={(e) => handleRemoveOption(e)}>Remove</button>
                    <br/>
                </div>
            )}
            <br/>

            <label htmlFor='date'>End Date: </label>
            <input id='date' type='datetime-local' onChange={handleDateChange}></input>
            <br/>

            <button onClick={handleSubmit}>Create</button>
        </div>
    )
}