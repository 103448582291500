import React, { useEffect, useState } from 'react';
import { USERID } from '../../App';
import { useNavigate } from 'react-router-dom';

export default function StatusCheck(props) {
  const [exemptionUsers, setExemptionUsers] = useState([])
  const [gSUsers, setGSUsers] = useState([])
  const [userPage, setUserPage] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    let url = `${props.constants.hostname}/api/secretary/exemptUsers`;
    fetch(url, {
      headers: {Authorization: `${localStorage.getItem(USERID)}`}
    })
      .then((res) => {
        if (!res.ok) {
          return false;
        }
        else {
          return res.json();
        }
      })
      .then((data) => {
        setExemptionUsers(data)
      })
      .catch((error) => { console.error(error); })
    url = `${props.constants.hostname}/api/secretary/goodStanding`
    fetch(url, {
      headers: {Authorization: `${localStorage.getItem(USERID)}`}
    })
      .then((res) => {
        if (!res.ok) {
          return false;
        }
        else {
          return res.json();
        }
      })
      .then((data) => {
        setGSUsers(data)
      })
      .catch((error) => { console.error(error); })
  }, [])

  function generateExemptionRow(user) {
    return (
      <tr key={user.DCE} className='userRow' onClick={() => setUserPage(user.DCE)}>
        <td className='userEntry'></td>
        <td className='userEntry'>{user.LastName}</td>
        <td className='userEntry'>{user.FirstName}</td>
        <td className='userEntry'>{user.ExemptDesc}</td>
      </tr>
    );
  }

  function handleUpdateAll() {
    if (window.confirm('This will now update all user\'s statuses for good standing.\nAre you sure you want to do this?\nIt might take a moment')
    ) {
      const url = `${props.constants.hostname}/api/updateStatus`;
      fetch(url, {
        headers: {Authorization: `${localStorage.getItem(USERID)}`}
      })
      .then((res) => {
        if (!res.ok) {
          return false;
        }
        return res;
      })
      .then((data) => {
        alert('Users have been updated');
        return data
      })
      .catch((error) => { console.error(error); })
    }
  }

  function generateGSRow(user) {
    return (
      <tr key={user.DCE} className='userRow' onClick={() => setUserPage(user.DCE)}>
        <td className='userEntry'></td>
        <td className='userEntry'>{user.LastName}</td>
        <td className='userEntry'>{user.FirstName}</td>
      </tr>
    );
  }

  if(userPage !== '') {
    navigate(`/secretary/user/${userPage}`)
  }

  return (
    <div>
      <h3>Users with Good Standing/Special Exemptions</h3>
      <h4>Click on a User to Change The Information</h4>
      <hr />
      <button onClick={handleUpdateAll}>Update Standings</button>
      <br />
      <h3>Current Users with Exemptions ({exemptionUsers.length}):</h3>
      <div className='tableDiv'>
        <table className='userTable'>
          <thead>
            <tr className='userRow userHeaderRow'>
              <th className='userHeader'>Section</th>
              <th className='userHeader'>Last Name</th>
              <th className='userHeader'>First Name</th>
              <th className='userHeader'>Exemption Description</th>
            </tr>
          </thead>
          <tbody>
          {exemptionUsers.map((user) => generateExemptionRow(user))}
          </tbody>
        </table>
      </div>
      <h3>
        Current Users With Good Standing Statuses ({gSUsers.length}):
      </h3>
      <div className='tableDiv'>
        <table className='userTable'>
          <thead>
            <tr className='userRow userHeaderRow'>
              <th className='userHeader'>Section</th>
              <th className='userHeader'>Last Name</th>
              <th className='userHeader'>First Name</th>
            </tr>
          </thead>
          <tbody>
            {gSUsers.map((user) => generateGSRow(user))}
          </tbody>
        </table>
      </div>
    </div>
  );
}