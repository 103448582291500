import React, { useEffect, useState } from 'react';
import { USERID } from '../App';
import { useNavigate } from 'react-router-dom';
import { authRole } from '../..';

export default function Admin(props) {
    const navigate = useNavigate()
    useEffect(() => {
    if(!authRole(props.userRole, props.constants.roles.admin)) {
        navigate('/404', {replace: true})
    }
    }, [props.userRole])

    const [sysLogs, setSysLogs] = useState([])
    const [alResponse, setAlResponse] = useState('')

    function pingAl() {
        const url = `${props.constants.hostname}/api/botPing`
        fetch(url, {
            headers: {Authorization: `${localStorage.getItem(USERID)}`}
        })
        .then((res) => {
            if (!res.ok) {
            return false;
            }
            return res.text();
        })
        .then((data) => {
            if (!data) { return; }
            setAlResponse(data)
        })
        .catch((error) => { console.error(error); });
    }

    useEffect(() => {
        pingAl()
        const url = `${props.constants.hostname}/api/logs`;
        fetch(url, {
            headers: {Authorization: `${localStorage.getItem(USERID)}`}
        })
        .then((res) => {
            if (!res.ok) {
            return false;
            }
            return res.json();
        })
        .then((data) => {
            if (!data) { return; }
            setSysLogs(data)
        })
        .catch((error) => { console.error(error); });
    }, [])

    function systemLogToTR(s) {
        function objToListOfP(o) {
            const retList = []
            for(const k in o) {
                retList.push(`${k}: ${o[k]}\n`)
            }
            return retList.map((e) => <p key={e}>{e}</p>)
        }
        return(
            <tr key={sysLogs.indexOf(s)}>
                <td>{s.date}</td>
                <td>{s.method}</td>
                <td>{s.path}</td>
                <td>{s.query ? Object.keys(s.query).length >= 1 ? objToListOfP(s.query) : 'N/A' : 'N/A'}</td>
                <td>{s.body ? Object.keys(s.body).length >= 1 ? objToListOfP(s.body) : 'N/A' : 'N/A'}</td>
            </tr>
        )
    }

    return(
        <div>
            <h2>Admin</h2>
            <h3>Al Status</h3>
            {alResponse !== '' ? alResponse : 'not loaded'}
            <h3>System Logs:</h3>
            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Method</th>
                        <th>Path</th>
                        <th>Query</th>
                        <th>Body</th>
                    </tr>
                </thead>
                <tbody>
                    {sysLogs.map((s) => systemLogToTR(s))}
                </tbody>
            </table>
        </div>
    )
}