import React from 'react';
import '../../css/themes.css'
import { THEME } from '../App';
import { THEMELIST } from '../ThemeLoader';

export default function Themes() {
    function handleThemeChange(newTheme) {
        localStorage.setItem(THEME, newTheme)
        window.location.reload()
    }

    return (
        <div id="themes">
            <h1>Themes</h1>
            <div>Customize your RIT Pep Band experience</div>
            <br/>
            <h3>Color Themes</h3>
            <div className="theme-list" id="solid-themes">
                <button onClick={() => handleThemeChange(THEMELIST.light)} className='default'>Default</button>
                <button onClick={() => handleThemeChange(THEMELIST.dark)} className='dark'>Dark</button>
                <button onClick={() => handleThemeChange(THEMELIST.red)} className='red'>Red</button>
                <button onClick={() => handleThemeChange(THEMELIST.blue)} className='blue'>Blue</button>
                <button onClick={() => handleThemeChange(THEMELIST.green)} className='green'>Green</button>
                <button onClick={() => handleThemeChange(THEMELIST.purple)} className='purple'>Purple</button>
                <button onClick={() => handleThemeChange(THEMELIST.prismarine)} className='prismarine'>Prismarine</button>
                <button onClick={() => handleThemeChange(THEMELIST.brown)} className='brown'>Brown</button>
                <button onClick={() => handleThemeChange(THEMELIST.navy)} className='navy'>Navy</button>
            </div>
            <br/>
            <h3>Pride Themes</h3>
            <div className="theme-list" id="pride-themes">
                <button onClick={() => handleThemeChange(THEMELIST.rainbow)} className='rainbow'/>
                <button onClick={() => handleThemeChange(THEMELIST.ace)} className='ace'/>
                <button onClick={() => handleThemeChange(THEMELIST.bi)} className='bi'/>
                <button onClick={() => handleThemeChange(THEMELIST.enby)} className='enby'/>
                <button onClick={() => handleThemeChange(THEMELIST.trans)} className='trans'/>
                <button onClick={() => handleThemeChange(THEMELIST.pan)} className='pan'/>
            </div>
            <h3>Seasonal Themes</h3>
            <div className="theme-list" id="seasonal-themes">
                <button value={THEMELIST.spooky} onClick={() => handleThemeChange(THEMELIST.spooky)} className='spooky'>Spooky</button>
                <button value={THEMELIST.holidays} onClick={() => handleThemeChange(THEMELIST.holidays)} className='holidays'>Holidays</button>
            </div>
            <h3>Dev Themes</h3>
            <div className="theme-list" id="dev-themes">
                <button onClick={() => handleThemeChange(THEMELIST.light)} className='default'>Peyton</button>
                <button onClick={() => handleThemeChange(THEMELIST.patrick)} className='patrick'>Patrick</button>
                <button onClick={() => handleThemeChange(THEMELIST.jensen)} className='jensen'>Jensen</button>
            </div>
        </div>
    )
}
