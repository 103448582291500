import React from 'react';

export default function CannotVote() {
    return (
        <div>
            <h3>You cannot vote as you do not have good standing</h3>
            <p>If you do have good standing (as indicated by the attendance page), Contact the Webmaster</p>
            <p>If you do not have good standing but feel that you should, Contact the Secretary</p>
        </div>
    )
}