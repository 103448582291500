import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { USERID } from '../../App';
import { useNavigate } from 'react-router-dom';

export default function Users(props) {
  const [users, setUsers] = useState([])
  const [filtered, setFiltered] = useState([])
  const [userPage, setUserPage] = useState('')
  const navigate = useNavigate()
  let section = ''

  useEffect(() => {
    const url = `${props.constants.hostname}/api/secretary/users`;
      fetch(url, {
        headers: {Authorization: `${localStorage.getItem(USERID)}`}
      })
      .then((res) => {
          if (!res.ok) {
              return false;
          }
          return res.json();
      })
      .then((data) => {
          if (!data) { return; }
          setUsers(data)
          setFiltered(data)
      })
      .catch((error) => { console.error(error); });
  }, [])

  function handleDownload() {
    const csvContent = `data:text/csv;charset=utf-8,${users.map((e) => `${e.SECTION},${e.LastName},${e.FirstName},${e.DCE}`).join('\n')}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    const now = new Date();
    link.setAttribute('download', `Users-(${now.getMonth() + 1}-${now.getDate()}-${now.getFullYear()}).csv`);
    document.body.appendChild(link);
    link.click();
  }
  
  function handleSearch(event) {
    const filteredUsers = [];
    let idx = 0;
    const searchVal = event.target.value.toLowerCase();
    users.filter((user) => {
      if (user.DCE.toLowerCase().includes(searchVal)
      || user.FirstName.toLowerCase().includes(searchVal)
      || user.LastName.toLowerCase().includes(searchVal)) {
        filteredUsers[idx] = user;
        idx++;
      }
      return filteredUsers;
    })
    setFiltered(filteredUsers)
    section = null;
  }

  function generateRow(user) {
    if (section === user.SECTION) {
      return (
        <tr key={user.DCE} className='userRow' onClick={() => setUserPage(user.DCE)}>
          <td className='userEntry'></td>
          <td className='userEntry'>{user.LastName}</td>
          <td className='userEntry'>{user.FirstName}</td>
          <td className='userEntrySeparate'>
            <div className='tooltip'>
              {user.WGameGo === 1 ? 'True' : 'False'}
              <span className='tooltiptext'>
                {user.SECTION}, {user.LastName}
              </span>
            </div>
          </td>
          <td className='userEntrySeparate'>
            <div className='tooltip'>
              {user.MGameGo === 1 ? 'True' : 'False'}
              <span className='tooltiptext'>
                {user.SECTION}, {user.LastName}
              </span>
            </div>
          </td>
          <td className='userEntrySeparate'>
            <div className='tooltip'>
              {user.GStanding === 1 ? 'True' : 'False'}
              <span className='tooltiptext'>
                {user.SECTION}, {user.LastName}
              </span>
            </div>
          </td>
          <td className='userEntrySeparate'>
            <div className='tooltip'>
              {user.FGStanding === 1 ? 'True' : 'False'}
              <span className='tooltiptext'>
                {user.SECTION}, {user.LastName}
              </span>
            </div>
          </td>
        </tr>
      );
    } else {
      section = user.SECTION;
      return (
        <tr key={user.DCE} className='userRow' onClick={() => setUserPage(user.DCE)}>
          <td className='userEntrySeparate'>{user.SECTION}</td>
          <td className='userEntrySeparate'>{user.LastName}</td>
          <td className='userEntrySeparate'>{user.FirstName}</td>
          <td className='userEntrySeparate'>
            <div className='tooltip'>
              {user.WGameGo === 1 ? 'True' : 'False'}
              <span className='tooltiptext'>
                {user.SECTION}, {user.LastName}
              </span>
            </div>
          </td>
          <td className='userEntrySeparate'>
            <div className='tooltip'>
              {user.MGameGo === 1 ? 'True' : 'False'}
              <span className='tooltiptext'>
                {user.SECTION}, {user.LastName}
              </span>
            </div>
          </td>
          <td className='userEntrySeparate'>
            <div className='tooltip'>
              {user.GStanding === 1 ? 'True' : 'False'}
              <span className='tooltiptext'>
                {user.SECTION}, {user.LastName}
              </span>
            </div>
          </td>
          <td className='userEntrySeparate'>
            <div className='tooltip'>
              {user.FGStanding === 1 ? 'True' : 'False'}
              <span className='tooltiptext'>
                {user.SECTION}, {user.LastName}
              </span>
            </div>
          </td>
        </tr>
      );
    }
  }

  if(userPage) {
    navigate(`/secretary/user/${userPage}`)
  }

  return (
    <div>
      <h3>User Information:</h3>
      <div className='secBarNav'>
        <FontAwesomeIcon
          icon='file-download'
          className='faEdit'
          onClick={() => handleDownload()}
        />
      </div>
      <hr />
      <form>
        <label>
          Search:
          <input
            onChange={(e) => handleSearch(e)}
            className='userSearch'
          />
        </label>
      </form>
      <div className='tableDiv'>
        <table className='userTable'>
          <thead>
            <tr className='userRow userHeaderRow'>
              <th className='userHeader'>Section</th>
              <th className='userHeader'>Last Name</th>
              <th className='userHeader'>First Name</th>
              <th className='userHeader'>W's Game Eligible</th>
              <th className='userHeader'>M's Game Eligible</th>
              <th className='userHeader'>Natural Good Standing</th>
              <th className='userHeader'>Future Good Standing</th>
            </tr>
          </thead>
          <tbody>
            {filtered.map((user) =>
              generateRow(user)
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
