import React, { useEffect, useState } from 'react';
import { USERID } from '../../App';
import { Link, useNavigate, useParams } from 'react-router-dom';

export default function Election(props) {
    const params = useParams()
    const [abstainable, setAbstainable] = useState('Y')
    const [electionName, setElectionName] = useState('')
    const [options, setOptions] = useState([])
    const [ordering, setOrdering] = useState([])
    const [unselected, setUnselected] = useState([])
    const [submit, setSubmit] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        let url = `${props.constants.hostname}/api/voting/canVote?electionID=${params.sElection}`;
        fetch(url, {
            headers: {Authorization: `${localStorage.getItem(USERID)}`}
        })
        .then((res) => {
          if (!res.ok) {
            return false;
          }
          return res.json();
        })
        .then((data) => {
          if(data) { return; } else {
                window.alert('You have already voted in this Election, Redirecting...')
                navigate('/vote')
          }
        })
        .catch((error) => { console.error(error); });

        // Get Election
        url = `${props.constants.hostname}/api/voting/election?electionID=${params.sElection}`;
        
        fetch(url, {
            headers: {Authorization: `${localStorage.getItem(USERID)}`}
        })
        .then((res) => {
          if (!res.ok) {
            return false;
          }
          return res.json();
        })
        .then((data) => {
          if(!data) { return; }
          if (data.length === 0) { 
            window.alert('This Election does not exist, Redirecting...')
            navigate('/vote')
          }
          setElectionName(data[0].ElectionName)
          setAbstainable(data[0].abstainable)
        })
        .catch((error) => { console.error(error); });

        // Get Candidates
        url = `${props.constants.hostname}/api/voting/electionCandidates?electionID=${params.sElection}`;
        fetch(url, {
            headers: {Authorization: `${localStorage.getItem(USERID)}`}
        })
        .then((res) => {
          if (!res.ok) {
            return false;
          }
          return res.json();
        })
        .then((data) => {
          if (!data) { return; }
          let initUnselected = []
          for(let i = 0; i < data.length; i++) {
            initUnselected = initUnselected.concat([{candidate_id: data[i].candidate_id, election_id: data[i].election_id, name: data[i].name}])
          }
          setOptions(data)
          setUnselected(initUnselected)
        })
        .catch((error) => { console.error(error); });

        // Get Election
        url = `${props.constants.hostname}/api/voting/election?electionID=${params.sElection}`;
        fetch(url, {
            headers: {Authorization: `${localStorage.getItem(USERID)}`}
        })
        .then((res) => {
          if (!res.ok) {
            return false;
          }
          return res.json();
        })
        .then((data) => {
          if (!data) { return; }
          setAbstainable(data[0].abstainable)
        })
        .catch((error) => { console.error(error); });
    }, [])

    function handleAddToOrder(event) {
        if(options.length === 2 && ordering.length === 1) {
            window.alert('You can only select one candidate')
        }
        else {
            let newOrdering = [...ordering]
            const newUnselected = [...unselected]
            for(let i = 0; i < newUnselected.length; i++) {
                // eslint-disable-next-line
                if(newUnselected[i].candidate_id == event.target.id) {
                    newOrdering = newOrdering.concat(newUnselected.splice(i, 1))
                    break
                }
            }
            setOrdering(newOrdering)
            setUnselected(newUnselected)
        }
    }

    function handleClearOrder() {
        let initUnselected = []
          for(let i = 0; i < options.length; i++) {
            initUnselected = initUnselected.concat([{candidate_id: options[i].candidate_id, election_id: options[i].election_id, name: options[i].name}])
          }
        setOrdering([])
        setUnselected(initUnselected)
    }

    function handleSubmit() {
        if(abstainable === 'N' && ordering.length !== options.length && options.length !== 2) {
            window.alert('You must vote for EVERY candidate in this Election')
            handleClearOrder()
        }
        else {
            const url = `${props.constants.hostname}/api/voting/castVote`
            fetch(url, {
                body: JSON.stringify({ election_id: params.sElection, ordering: ordering, options: options}),
                headers: {
                Authorization: `${localStorage.getItem(USERID)}`,
                'Content-Type': 'application/json',
                },
                method: 'POST',
            })
            .then((res) => {
                if (!res.ok) {
                    window.alert('An Error Occured, Try again and if it still happens DM the webmaster')
                    console.log(res)
                    return false;
                }
                else {
                    setSubmit(true)
                    window.alert('Successfully Voted!')
                    return true;
                }
            })
            .catch((error) => { console.error(error); })
        }
    }
    
    function handleAbstain(event) {
        if(window.confirm('Are you sure you want to abstain?')) {
            setOrdering([])
            handleSubmit(event)
        }
    }

    function convertOrderingToIDs() {
        let newOrdering = []
        for(let i = 0; i < ordering.length; i++) {
            newOrdering = newOrdering.concat(ordering[i].candidate_id)
        }
        setOrdering(newOrdering)
    }
    
    function handleVote(event) {
        if(window.confirm('Are you sure you want to vote?')) {
            convertOrderingToIDs()
            handleSubmit(event)
        }
    }

    if(submit) {
        navigate('/vote')
    }

    return (
        <div>
            <Link to='/vote' className='secBarLink'>
                <p className='secBarText'>Back to "Election Home"</p>
            </Link>
            <h3>{electionName}</h3>
            <p>Click on Candidates in order of preference, higher indicates better</p>
            {options.length === 2 ?
                <div>
                    <p>This election only has 2 options, select one and press submit.</p>
                    {abstainable === 'Y' ?
                        <div>
                            <p>Alternatively, you can abstain if you wish.</p>
                        </div>
                        :            
                        null
                    }
                    {abstainable === 'N' ?
                        <p>You cannot abstain from this election, you must select a candidate</p>
                        :            
                        null
                    }
                </div>
                :
                null
            }
            {options.length !== 2 ?
                <div>
                    {abstainable === 'Y' ?
                    <div>
                        <p>If you are opposed to some of the candidates, you may select only the ones you approve of. 
                        Leaving a candidate unselected will count as an abstain, if 1/3 of the band abstains from a candidate, they are prevented from winning the election</p>
                        <p>If you are opposed to all candidates, you can hit abstain</p>
                    </div>
                    :            
                    null
                    }
                    {abstainable === 'N' ?
                        <p>You cannot abstain from this election, you must vote for every candidate</p>
                        :            
                        null
                    }
                </div>
                : 
                null
            } 
            <br/>
            <h3>Candidates:</h3>
            {unselected.map((option) =>
                <button id={option.candidate_id} key={option.candidate_id} value={option.name} onClick={(e) => handleAddToOrder(e)}>{option.name}</button>
                )}
            <br/>
            <h3>Selection:</h3> 
            <ol style={{float: 'none', listStyleType: 'number'}}>
                {ordering.map((order) =>
                    <li key={order.candidate_id} style={{float: 'none', listStyle: 'number'}}>{order.name}</li>
                )}
            </ol>
            <button onClick={handleClearOrder}>Clear Selection</button>
            <button onClick={handleVote}>Submit</button>
            {abstainable === 'Y' ?
                <button onClick={handleAbstain}>Abstain</button>
                :
                null
            }
        </div>
    )
}