import React, { useEffect, useState } from 'react';
import { blankCheck, nameCheck, emailCheck, dceCheck } from '../../Errors';
import { USERID } from '../../App';
import { useNavigate, useParams } from 'react-router-dom';

const SECTIONS = [
  {name: 'Flutes', checked: false, disabled: false}, 
  {name: 'Clarinets', checked: false, disabled: false},
  {name: 'Saxophones', checked: false, disabled: false},
  {name: 'Trumpets', checked: false, disabled: false},
  {name: 'Mellophones', checked: false, disabled: false},
  {name: 'TenorSaxophones', checked: false, disabled: false},
  {name: 'Trombones', checked: false, disabled: false},
  {name: 'Lows', checked: false, disabled: false},
  {name: 'Percussion', checked: false, disabled: false}
];

export default function UserEdit(props) {
  const params = useParams()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [dce, setDCE] = useState('')
  const [snowflake, setSnowflake] = useState('')
  const [snowChange, setSnowchange] = useState(false)
  const [queryDCE, setqueryDCE] = useState('')
  const [role, setRole] = useState('M')
  const [section, setSection] = useState('---')
  const [status, setStatus] = useState('---')
  const [mGStanding, setMGStanding] = useState(false)
  const [exempt, setExempt] = useState(false)
  const [exemptDesc, setExemptDesc] = useState('')
  const [submit, setSubmit] = useState(false)
  const [error, setError] = useState(false)
  const [edit, setEdit] = useState(false)
  const [mGStandingTime, setMGStandingTime] = useState(0)
  const [extra, setExtra] = useState(false)
  const [trueExtra, setTrueExtra] = useState([])
  const [tempExtra, setTempExtra] = useState(JSON.parse(JSON.stringify(SECTIONS)))
  const navigate = useNavigate()

  useEffect(() => {
    if (params.selectedUser != null && params.selectedUser !== undefined) {
      let url = `${props.constants.hostname}/api/secretary/user?editUser=${params.selectedUser}`;
      fetch(url, {
        headers: {Authorization: `${localStorage.getItem(USERID)}`}
      })
      .then((res) => {
        if (!res.ok) {
          return false;
        }
        else {
          return res.json();
        }
      })
      .then((data) => {
        setFirstName(data[0].FirstName)
        setLastName(data[0].LastName)
        setEmail(data[0].Email)
        setDCE(data[0].DCE)
        setSnowflake(data[0].Snowflake)
        setqueryDCE(data[0].DCE) 
        setRole(data[0].Role)
        setSection(data[0].SECTION)
        setStatus(data[0].Status)
        setMGStanding(data[0].MGStanding)
        setExempt(data[0].Exempt)
        setExemptDesc(data[0].ExemptDesc)
        setEdit(true)
        setMGStandingTime(data[0].MGStandingTime)
        delete params.selectedUser
      })
      .catch((e) => { console.error(e); })
      url = `${props.constants.hostname}/api/secretary/userSections?user=${params.selectedUser}`;
      fetch(url, {
        headers: {Authorization: `${localStorage.getItem(USERID)}`}
      })
      .then((res) => {
        if (!res.ok) {
          return false;
        }
        else {
          return res.json();
        }
      })
      .then((data) => {
        const localSec = JSON.parse(JSON.stringify(SECTIONS));
        data.forEach((s) => {
          localSec.forEach((innerSec) => {
            if (innerSec.name === s.SECTION) {
              innerSec.checked = true;
            }
          });
        });
        const sec = localSec.find((s) => s.name === section)
        if (sec !== undefined) {
          sec.disabled = true;
        }
        setTempExtra(localSec)
        const convert = data.map((s) => ({name: s.SECTION, checked: true, disabled: false}))
        setTrueExtra(convert)
        if (convert.length !== 0) {
          setExtra(true)
        }
      })
      .catch((e) => { console.error(e); });
    }
  })

  function onSnowflakeChange(event) {
    if(!snowChange) {
      const response = window.confirm("WARNING: Only Edit the Snowflake if you REALLY know what you're doing")
      if(response) {
        setSnowchange(true)
        setSnowflake(event.target.value)
      }
    } else {
      setSnowflake(event.target.value)
    }
  }

  function onSectionChange(event) {
    const localSections = tempExtra;
    const s = localSections.find((sec) => sec.name === event.target.value);
    if (s !== undefined) {
      s.checked = false;
      s.disabled = true;
    }
    const curSec = localSections.find((sec) => sec.name === section);
    if (curSec !== undefined) {
      curSec.disabled = false;
    }
    setSection(event.target.value)
    setTempExtra(localSections)
  }

  function onExtraSectionCheck(sec) {
    const localSections = [...tempExtra];
    const secti = localSections.find((s) => s.name === sec.name);
    secti.checked = !sec.checked;
    setTempExtra(localSections)
  }

  function handleExtra() {
    const adjustedList = [];
    tempExtra.forEach((s) => {
      let included = false;
      trueExtra.forEach((innerSection) => {
        if (innerSection.name === s.name) {
          included = true;
        }
      });
      if (!included && s.checked) {
        adjustedList.push(s);
      } else if (included && !s.checked) {
        adjustedList.push(s);
      }
    });
    return adjustedList;
  }

  function handleManualGoodStanding(event) {
    setMGStanding(event.target.checked)
    setMGStandingTime(new Date())
  }

  function errorCheck() {
    if (blankCheck(firstName)) {
      console.error('Blank field: First Name')
      alert('Blank field: First Name')
      return false;
    }
    if (nameCheck(firstName)) {
      console.error(`Invalid First Name: ${firstName}`);
      alert(`Invalid First Name: ${firstName}\nPlease fix input`);
      return false;
    }
    if (blankCheck(lastName)) {
      console.error('Blank field: Last Name')
      alert('Blank field: Last Name')
      return false;
    }
    if (nameCheck(lastName)) {
      console.error(`Invalid Last Name: ${lastName}`);
      alert(`Invalid Last Name: ${lastName}\nPlease fix input`);
      return false;
    }
    if (blankCheck(dce)) {
      console.error('Blank field: DCE')
      alert('Blank field: DCE')
      return false;
    }
    if (dceCheck(dce)) {
      console.error(`Invalid DCE: ${dce}`);
      alert(`Invalid DCE: ${dce}\nPlease fix input`);
      return false;
    }
    if (blankCheck(email)) {
      console.error('Blank field: Email')
      alert('Blank field: Email')
      return false;
    }
    if (emailCheck(email)) {
      console.error(`Invalid Email: ${email}`);
      alert(`Invalid Email: ${email}\nPlease fix input`);
      return false;
    }
    if (email.includes('@g.rit.edu')) {
      setEmail(`${dce}@rit.edu`)
    }
    return true;
  }

  function extraSectionRender() { 
    return (
      <div className='extraSec'>
        {tempExtra.map((s) =>
          <label 
            className='secretaryLabel sectionCheck align-center'
            style={s.disabled ? {color: 'var(--text)', textDecoration: 'line-through', fontStyle: 'italic'}
                              : {color: 'var(--text)'}}
            key={s.name}
            disabled={s.disabled}
          > {s.name}
            <input 
              type='checkbox'
              className='sectionCheck'
              onChange={() => onExtraSectionCheck(s)}
              checked={s.checked}
              disabled={s.disabled}
            />
          </label>
        )}
      </div>
    )
  }

  function submitData() {
    if(errorCheck()) {
      setError(false)
      const newUser = {
        FirstName: firstName,
        LastName: lastName,
        Email: email,
        DCE: dce,
        Role: role,
        Section: section,
        Status: status,
        MGStanding: mGStanding ? 1 : 0,
        Exempt: exempt ? 1 : 0,
        ExemptDesc: exemptDesc,
        MGStandingTime: mGStandingTime,
        ExtraSections: handleExtra(),
        Snowflake: snowflake
      }
      const url = `${props.constants.hostname}/api/secretary/register`;
      if (edit) {
        fetch(url,
          {
            body: JSON.stringify({ user: newUser, queryDCE: queryDCE }),
            headers: {
              Authorization: `${localStorage.getItem(USERID)}`,
              'Content-Type': 'application/json',
            },
            method: 'PUT',
          })
        .then((res) => {
          console.log(res.status)
          switch(res.status) {
            case 200:
              setSubmit(true)
              break;
            case 201:
              setSubmit(true)
              break;
            case 502:
              setError(2)
              break;
            case 504:
              setError(3)
              break;
            default:
              setError(1)
          }
        })
        .catch((e) => { console.error(e); })
      } else {
        fetch(url,
          {
            body: JSON.stringify({ user: newUser, queryDCE: queryDCE }),
            headers: {
              Authorization: `${localStorage.getItem(USERID)}`,
              'Content-Type': 'application/json',
            },
            method: 'POST',
          })
        .then((res) => {
          if (!res.ok) {
            setError(true)
            return false;
          }
          else {
            setSubmit(true)
            return true;
          }
        })
        .catch((e) => { console.error(e); })
      }
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    submitData()
  }

  if(submit) {
    navigate(`/secretary/user/${dce}`)
  }

  return (
    <div>
      {error === 1 ?
        <h2>An Error Occurred: User Already Exists</h2>
        :
        null
      }
      {error === 2 ?
        <div>
          <h2>An Error Occurred With AL: Failed to Parse; Notify the Webmaster</h2>
          <h3>Changes were saved to DB</h3>
        </div>
        :
        null
      } 
      {error === 3 ?
        <div>
          <h2>An Error Occurred With AL: Did Not Receive; Notify the Webmaster</h2>
          <h3>Changes were saved to DB</h3>
        </div>
        :
        null
      }
      <h3>Create/Edit User</h3>
      <hr />
      <form className='userForm'>
        <label className='secretaryLabel'>
          First Name:
          <input
            type='text'
            name='FirstName'
            onChange={(e) => setFirstName(e.target.value)}
            value={firstName}
            className='secretaryInput'
            maxLength='50'
          />
        </label>
        <label className='secretaryLabel'>
          Last Name:
          <input
            type='text'
            name='LastName'
            onChange={(e) => setLastName(e.target.value)}
            value={lastName}
            className='secretaryInput'
            maxLength='50'
          />
        </label>
        <label className='secretaryLabel'>
          Email:
          <input
            type='text'
            name='Email'
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            className='secretaryInput'
            maxLength='17'
          />
        </label>
        <label className='secretaryLabel'>
          DCE:
          <input
            type='text'
            name='DCE'
            onChange={(e) => setDCE(e.target.value)}
            value={dce}
            className='secretaryInput'
            maxLength='7'
          />
        </label>
        <label className='secretaryLabel'>
          Snowflake:
          <input
            type='text'
            name='Snowflake'
            onChange={(e) => onSnowflakeChange(e)}
            value={snowflake}
            className='secretaryInput'
            maxLength='50'
          />
        </label>
        <label className='secretaryLabel'>
          Role:
          <select
            value={role}
            onChange={(e) => setRole(e.target.value)}
            className='secretaryInput'
          >
            <option default value='M'>Member</option>
            <option value='A'>Admin</option>
            <option value='P'>President</option>
            <option value='V'>Vice President</option>
            <option value='T'>Treasurer</option>
            <option value='C'>Conductor</option>
            <option value='S'>Secretary</option>
            <option value='L'>Section Leader</option>
          </select>
        </label>
        <label className='secretaryLabel'>
          Section:
          <select
            value={section}
            onChange={(e) => onSectionChange(e)}
            className='secretaryInput'
          >
            <option default value='---'>---</option>
            <option value='Flutes'>Flutes</option>
            <option value='Clarinets'>Clarinets</option>
            <option value='Saxophones'>Saxophones</option>
            <option value='Trumpets'>Trumpets</option>
            <option value='Mellophones'>Mellophones</option>
            <option value='TenorSaxophones'>Tenor Saxophones</option>
            <option value='Trombones'>Trombones</option>
            <option value='Lows'>Lows</option>
            <option value='Percussion'>Percussion</option>
          </select>
        </label>
        <label className='secretaryLabel align-center'>
          Extra Sections:
          <input 
            type='checkbox'
            onChange={() => setExtra(!extra)}
            checked={extra}
          />
        </label>
        <div style={{display: extra ? '' : 'none'}}>
          {extraSectionRender()}
        </div>
        <label className='secretaryLabel'>
          Status:
          <select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            className='secretaryInput'
          >
            <option default value='---'>---</option>
            <option value='C'>Current Student</option>
            <option value='A'>Active Alumni</option>
            <option value='I'>Inactive Alumni</option>
          </select>
        </label>
        <label className='secretaryLabel'>
          Good Standing (For THIS Semester):
          <input
            type='checkbox'
            className='secretaryInput'
            onChange={(e) => handleManualGoodStanding(e)}
            checked={mGStanding}
          />
        </label>
        <label className='secretaryLabel'>
          Exempt (select for true):
          <input
            type='checkbox'
            className='secretaryInput'
            onChange={(e) => setExempt(e.target.value)}
            checked={exempt}
          />
        </label>
        <label className='secretaryLabel'>
          Exemption Description:
          <input
            type='text'
            name='ExemptionDesc'
            onChange={(e) => setExemptDesc(e.target.value)}
            value={exemptDesc}
            className='secretaryInput'
            maxLength='50'
          />
        </label>
        <button
          onClick={handleSubmit}
          className='secretarySubmit'
        >
          Submit
        </button>
      </form>
    </div>
  );
}