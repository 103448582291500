import React, { useEffect, useState } from 'react';
import { USERID } from '../../App';
import { useNavigate } from 'react-router-dom';

const INIT_HOUR_VALS = [
    {name: 'MHockey', value: 4},
    {name: 'WHockey', value: 4},
    {name: 'MSoccer', value: 3},
    {name: 'WSoccer', value: 3},
    {name: 'MLax', value: 2},
    {name: 'WLax', value: 2},
    {name: 'Rehearsal', value: 1}
]

export default function Hours(props) {
    const [otherEvents, setOtherEvents] = useState([])
    const [users, setUsers] = useState([])
    const [attendance, setUserAttendance] = useState([])
    const [hourValues, setHourValues] = useState(INIT_HOUR_VALS)
    const [userPage, setUserPage] = useState('')
    const navigate = useNavigate()

    function reCalcUserHours() {
        if(users.length === 0) {
            return
        }
        const newUsers = [...users]
        for(const user of users) {
            let hours = 0
            for(const eventType of hourValues) {
                hours += eventType.value * user[`${eventType.name}Count`]
            }
            for(const atten of attendance) {
                if(atten.MemberDCE === user.DCE) {
                    hours += otherEvents.find((e) => e.Eid === atten.Eid).Hours
                }
            }
            user.Hours = hours
        }
        setUsers(newUsers)
    }

    useEffect(() => {
        let url = `${props.constants.hostname}/api/secretary/usersAttendedMoreThanOneEvent`;
        fetch(url, {
            headers: {Authorization: `${localStorage.getItem(USERID)}`}
        })
        .then((res) => {
            if (!res.ok) {
                return false;
            }
            else {
                return res.json();
            }
        })
        .then((data) => {
            for(const user of data) {
                user.Hours = 0
            }
            setUsers(data)
            reCalcUserHours()
        })
        .catch((error) => { console.error(error); })
        
        url = `${props.constants.hostname}/api/secretary/otherEvents`
        fetch(url, {
            headers: {Authorization: `${localStorage.getItem(USERID)}`}
        })
        .then((res) => {
            if (!res.ok) {
                return false;
            }
            else {
                return res.json();
            }
        })
        .then((data) => {
            if(data.length > 0) {
                for(const element of data) {
                    element.Hours = 0
                }
                setOtherEvents(data)
                url = `${props.constants.hostname}/api/secretary/otherEventAttendance`;
                fetch(url, {
                    headers: {Authorization: `${localStorage.getItem(USERID)}`}
                })
                .then((res) => {
                    if (!res.ok) {
                        return false;
                    }
                    else {
                        return res.json();
                    }
                })
                .then((data2) => {
                    setUserAttendance(data2)
                })
                .catch((error) => { console.error(error); })
            }
        })
        .catch((error) => { console.error(error); })
    }, [])

    function updateTypeHourValue(event) {
        const newHourValues = [...hourValues]
        for(const type of newHourValues) {
            if(type.name === event.target.id) {
                type.value = event.target.value
            }
        }
        setHourValues(newHourValues)
        reCalcUserHours()
    }

    function convertEventTypeToRow(type) {
        return(
            <tr key={type.name}>
                <td>{type.name}</td>
                <td><input id={type.name} defaultValue={type.value} onChange={(event) => updateTypeHourValue(event)}></input></td>
            </tr>
        )
    }

    function updateHours(event) {
        if(event.target.value > 4) {
            window.alert('Individual Events cannot be worth more than 4 hours\n(at least at the time of writing this)')
            event.target.value = 4
            return
        }
        const newOtherEvents = [...otherEvents]
        for(const oEvent of newOtherEvents) {
            if(oEvent.Name === event.target.id) {
                oEvent.Hours = parseFloat(event.target.value)
            }
        }
        setOtherEvents(newOtherEvents)
        reCalcUserHours()
    }

    function generateOtherEventRow(otherEvent) {
        return (
            <tr key={otherEvent.Name}>
                <td>{otherEvent.Name}</td>
                <td>{otherEvent.DAY}</td>
                <td><input id={otherEvent.Name} defaultValue={otherEvent.Hours} onChange={(event) => updateHours(event)}></input></td>
            </tr>
        )
    }

    let section = ''
    function generateUserRow(user) {
        if (section === user.SECTION) {
          return (
            <tr key={user.DCE} className='userRow' onClick={() => setUserPage(user.DCE)}>
              <td className='userEntry'></td>
              <td className='userEntry'>{user.LastName}</td>
              <td className='userEntry'>{user.FirstName}</td>
              <td className='userEntry'>{parseFloat(user.Hours)}</td>
            </tr>
          );
        } else {
          section = user.SECTION;
          return (
            <tr key={user.DCE} className='userRow' onClick={() => setUserPage(user.DCE)}>
              <td className='userEntrySeparate'>{user.SECTION}</td>
              <td className='userEntrySeparate'>{user.LastName}</td>
              <td className='userEntrySeparate'>{user.FirstName}</td>
              <td className='userEntrySeparate'>{parseFloat(user.Hours)}</td>
            </tr>
          );
        }
    }

    if(userPage) {
        navigate(`/secretary/user/${userPage}`)
    }

    return(
        <div>
            <h3>Hours Calculator</h3>
            <h4>Event Type Values</h4>
            <table>
                    <thead>
                        <tr className='userRow userHeaderRow'>
                            <th className='userHeader'>Type</th>
                            <th className='userHeader'>Hour Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {hourValues.map((t) => convertEventTypeToRow(t))}
                    </tbody>
                </table>
            <h4>Other Events</h4>
            {otherEvents.length > 0 ?
                <table>
                    <thead>
                        <tr className='userRow userHeaderRow'>
                            <th className='userHeader'>Name</th>
                            <th className='userHeader'>Date</th>
                            <th className="userHeader">Hours</th>
                        </tr>
                    </thead>
                    <tbody>
                        {otherEvents.map((e) => generateOtherEventRow(e))}
                    </tbody>
                </table>
            :
            <h3>No 'Other' events have happened this year</h3>
            }
            <br/>
            <h4>Users</h4>
            <table>
                <thead>
                    <tr className='userRow userHeaderRow'>
                        <th className='userHeader'>Section</th>
                        <th className='userHeader'>Last Name</th>
                        <th className='userHeader'>First Name</th>
                        <th className='userHeader'>Hours</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((u) => generateUserRow(u))}
                </tbody>
            </table>
            
        </div>
    )
}