import React, { useEffect, useState } from 'react';
import { USERID } from '../App';

export default function PlayAll(props) {
    const [songList, setSongList] = useState([])

    useEffect(() => {
        const url = `${props.constants.hostname}/api/music/mp3Songs`;
        fetch(url, {
            headers: { Authorization: `${localStorage.getItem(USERID)}` }
        })
        .then((res) => {
        if (!res.ok) {
            return false;
        }
        return res.json();
        })
        .then((data) => {
        if (!data) {
            return;
        }
        setSongList(data)
        })
        .catch((error) => {
        console.error(error);
        });
    }, [])
    return(
        <div>
            {songList.map((s) => {
                return (
                    <audio controls autoPlay>
                    <source
                        src={`${props.constants.hostname}/api/music/mp3?title=${s.Title}&user=${localStorage.getItem(USERID)}`}
                        type='audio/mpeg'
                    ></source>
                    Your browser does not support the audio element.
                    </audio>
                )
            })}
        </div>
    )
}