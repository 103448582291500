import React, { useEffect } from 'react';
import '../../css/secretary.css'
import { Route, Routes, useNavigate } from 'react-router-dom';

import Home from './SecretaryPages/Home'
import Users from './SecretaryPages/Users';
import EmailLists from './SecretaryPages/EmailLists';
import Events from './SecretaryPages/Events';
import Event from './SecretaryPages/Event';
import EventEdit from './SecretaryPages/EventEdit';
import UserEdit from './SecretaryPages/UserEdit';
import CSV from './SecretaryPages/csv';
import CSVEvent from './SecretaryPages/csvEvent';
import UserToEvent from './SecretaryPages/userToEvent';
import User from './SecretaryPages/User';
import StatusCheck from './SecretaryPages/StatusCheck';
import Statistics from './SecretaryPages/Statistics';
import { authRole } from '../..';
import Hours from './SecretaryPages/Hours';

export default function Secretary(props) {
  const navigate = useNavigate()
  useEffect(() => {
    if(!authRole(props.userRole, props.constants.roles.secretary)) {
      navigate('/404', {replace: true})
    }
  }, [props.userRole])

  return (
    <div id="secretary">
      <h1>Secretary</h1>
      <Home constants={props.constants} cssOff={props.cssOff}/>
      <Routes>
        <Route path='users' element={<Users constants={props.constants} cssOff={props.cssOff}/>}/>
        <Route exact path='user/:selectedUser' element={<User constants={props.constants} cssOff={props.cssOff}/>}/>
        <Route exact path='userEdit/:selectedUser' element={<UserEdit constants={props.constants} cssOff={props.cssOff}/>}/>
        <Route exact path='userEdit' element={<UserEdit constants={props.constants} cssOff={props.cssOff}/>}/>
        <Route path='fileUpload' element={<CSV constants={props.constants} cssOff={props.cssOff}/>}/>
        <Route path='events' element={<Events constants={props.constants} cssOff={props.cssOff}/>}/>
        <Route path='event/:eventID' element={<Event constants={props.constants} cssOff={props.cssOff}/>}/>
        <Route path='eventEdit/:eventID' element={<EventEdit constants={props.constants} cssOff={props.cssOff}/>}/>
        <Route path='eventEdit' element={<EventEdit constants={props.constants} cssOff={props.cssOff}/>}/>
        <Route path='eventFileUpload' element={<CSVEvent constants={props.constants} cssOff={props.cssOff}/>} />
        <Route path='userToEvent' element={<UserToEvent constants={props.constants} cssOff={props.cssOff}/>} />
        <Route path='statusAudit' element={<StatusCheck constants={props.constants} cssOff={props.cssOff}/>} />
        <Route path='statistics' element={<Statistics constants={props.constants} cssOff={props.cssOff}/>} />
        <Route path='emailLists' element={<EmailLists constants={props.constants} cssOff={props.cssOff}/>}/>
        <Route path='hours' element={<Hours constants={props.constants} cssOff={props.cssOff}/>}/>
      </Routes>
    </div>
  );
}
