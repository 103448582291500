import React, { useEffect, useState } from 'react';
import { USERID } from '../../App';
import { Link } from 'react-router-dom';
import '../../../css/voting.css';

export default function Results(props) {
    const [totalGoodStanding, setTotalGoodStanding] = useState(0)
    const [quorums, setQuorums] = useState([])
    const [voteTallies, setVoteTallies] = useState([])
    const now = new Date()

    useEffect(() => {
        const url = `${props.constants.hostname}/api/voting/results`;
        fetch(url, {
            headers: { Authorization: `${localStorage.getItem(USERID)}`},
        })
        .then((res) => {
          if (!res.ok) {
            return false;
          }
          return res.json();
        })
        .then((data) => {
          if (!data) { 
            return; 
          }
          setTotalGoodStanding(data.totalGoodStanding[0].numGoodStanding)
          setVoteTallies(data.votes)
          setQuorums(data.quorums)
        })
        .catch((error) => { console.log(error); });
    }, [])

    function msToDHM(ms) {
        const days = Math.floor(ms / (24 * 60 * 60 * 1000));
        const daysms = ms % (24 * 60 * 60 * 1000);
        const hours = Math.floor(daysms / (60 * 60 * 1000));
        const hoursms = ms % (60 * 60 * 1000);
        const minutes = Math.ceil(hoursms / (60 * 1000));
        return `${days} days, ${hours} hours, ${minutes} minutes`;
      }

    const electionArray = {}
    for(const election of quorums) {
        election.EndDate = new Date(election.EndDate)
        electionArray[election.election_id] = {Election: election, Candidates: []}
    }
    for(const candidate of voteTallies) {
        electionArray[candidate.election_id].Candidates.push(candidate)
    }

    return (
        <div>
            <Link
                to='/vote'
                className='secBarLink'
            >
                <p className='secBarText'>Back to "Election Home"</p>
            </Link>
            <h3>Results</h3>
            <p>Total Good Standing Members: {totalGoodStanding}</p>
            <p>Votes required to hit Quorom: {Math.ceil(totalGoodStanding / 2)}</p>
            <h4>Election Results</h4>
            <div style={{display: 'flex', width: '80%', flexWrap: 'wrap'}}>
                {Object.values(electionArray).reverse().map((e) =>
                    <div className='resultsDiv' key={e.Election.election_id}>
                        <p>{e.Election.ElectionName} - {e.Election.votedCount} votes</p>
                        <p>This Election {e.Election.EndDate - now > 0 ? `closes in ${msToDHM(e.Election.EndDate - now)}` : 'IS CLOSED'}</p>
                        <table className='resultsTable'>
                            <thead>
                                <tr className='resultsCandidateRow'>
                                    <th>Candidate</th>
                                    <th>Points</th>
                                    <th>Abstains</th>
                                </tr>
                            </thead>
                            <tbody>
                                {e.Candidates.map((c) => 
                                    <tr className='resultsCandidateRow' key={c.candidate_id}>
                                        <td>{c.name}</td>
                                        <td>{c.PointsEarned}</td>
                                        <td>{c.Abstains}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    )
}
